import AOS from "aos";
import $ from 'jquery';
window.$ = window.jQuery = $;

$(function () {
  // Toggle menu
  $('.js-toggle-menu').on('click', () => {
    $('.js-menu').toggleClass('is-open')
    $('body').toggleClass('is-locked')
  })
})

// Initialize Aos
AOS.init({
  once: true
})
